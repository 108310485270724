import {
  Flex,
  Image,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  Button,
  Link,
  useColorMode,
  useBreakpoint,
} from "@chakra-ui/react";
import { useState } from "react";
import AboutMeVector from "./AboutMeVector.svg";
import CardCarousel from "./CardCarousel";
import ResumeFile from "./KahHo_Resume.pdf";

const AboutSection: React.FC = () => {
  const [tabIndex, setTabIndex] = useState(0);
  const { colorMode } = useColorMode();
  const deviceSize = useBreakpoint();
  const deviceSmall =
    deviceSize === "base" || deviceSize === "sm" ? true : false;
  return (
    <Flex position="relative" marginTop="40px" id={deviceSmall ? "" : "about"}>
      <Image
        height="160%"
        position="absolute"
        top="-60px"
        left={{ base: "-70vw", md: "-330px" }}
        draggable="false"
        zIndex="0.5"
        src={AboutMeVector}
      />
      <Flex
        width="100%"
        padding="30px"
        alignItems="center"
        justifyContent="center"
        zIndex="5"
        gap={{ base: "0", md: "100px" }}
        flexDir={{ base: "column-reverse", md: "row" }}
      >
        <CardCarousel tabIndex={tabIndex} />
        <Flex
          flexDir="column"
          height="380px"
          width={{ base: "80vw", md: "30vw" }}
          marginTop="-60px"
        >
          <Text
            fontSize={{ base: "4xl", md: "5xl" }}
            color={colorMode === "light" ? "#0F225E" : "#FFF"}
            id={deviceSmall ? "about" : ""}
          >
            ABOUT ME
          </Text>
          <Tabs onChange={(index) => setTabIndex(index)}>
            <TabList fontFamily="fjalla">
              <Tab>Life</Tab>
              <Tab>Education</Tab>
              <Tab>Experience</Tab>
            </TabList>
            <TabPanels
              fontFamily="poppins"
              fontSize={{ base: "sm", md: "md" }}
              marginTop={{ base: "320px", md: "0" }}
            >
              <TabPanel padding="10px 0 0 0">
                <Text>
                  I love exploring new places and be it locally or overseas, and
                  I also consider myself a foodie with an insatiable appetite!
                  In my free time, I enjoy playing instruments like the guitar
                  and the piano keyboard. I'm a huge alternative-rock fan, so
                  odds are you could probably catch me humming to The Strokes or
                  Imagine Dragons when I'm working! I also enjoy cycling and
                  taking in the nature. Additionally, I also find it
                  entertaining to attempt programming questions, and work on my
                  own fun projects, such as this website you see here!
                </Text>
              </TabPanel>
              <TabPanel padding="10px 0 0 0">
                <Text>
                  I graduated from Maris Stella High School in 2018, and
                  proceeded to pursue a diploma in Information Technology from
                  Ngee Ann Polytechnic, graduating with a GPA of 3.94/4.00 in
                  2022. After which, I served my National Service in the Army as
                  a Signal Specialist, having graduated from the Specialist
                  Cadet School and Signals Institute. Currently, I am pursuing a
                  bachelors degree in Computer Science at the National
                  University of Singapore.
                </Text>
              </TabPanel>
              <TabPanel padding="10px 0 0 0">
                <Text>
                  I got introduced to coding in secondary 1 through Scratch, and
                  since then, I’ve discovered the marvels of coding, how
                  stringing together text can create complex applications and
                  solve real-world problems. I've interned and worked with a
                  technology company Klass, for about 10 months, working on
                  projects involving edge computing and AI. I've also worked on
                  numerous development projects, from full-stack web development
                  to microservices, all available on LinkedIn.
                </Text>
              </TabPanel>
            </TabPanels>
          </Tabs>
          <Button
            marginTop="14px"
            width={{ base: "80vw", md: "150px" }}
            padding="20px"
            borderRadius="30px"
            color="#FFF"
            bgColor="#3C648C"
            _hover={{ bgColor: "#E9E9E9" }}
            alignSelf={{ base: "center", md: "start" }}
          >
            <Link
              href={ResumeFile}
              download="KahHo_Resume.pdf"
              _hover={{ textDecor: `none` }}
            >
              DOWNLOAD CV
            </Link>
          </Button>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default AboutSection;
